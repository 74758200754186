import { UnidadeEmpresa } from './../../model/unidadeEmpresa';
import { TitulosCobranca } from './../../model/titulosCobranca';
import { FormControl, FormGroup } from '@angular/forms';
export class ParamConsulta {
    index: number = 1;
    order: Order = new Order();

    alterarOrdenacao(propriedade: string, direcao: string) {
      this.order.dir = direcao;
      this.order.prop = propriedade;
    }
}

class Order {
    dir: string = "";
    prop: string = "";
}

export class ParamPesquisa {

    constructor() { }

    titulo: TitulosCobranca;
    unidade: UnidadeEmpresa;
}

export interface ConsultaPaginada {
  items?: any;
  pageIndex?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
}

export interface IParamConsulta {
  index: number;
  order: IParamConsultaOrder;
}

export type TParamConsultaForm = {
  [f in keyof IParamConsulta]:
    f extends 'order' ? FormGroup<TParamConsultaOrderForm> :
    FormControl<ParamConsulta[f]> | null;
}

export interface IParamConsultaOrder {
  dir: string;
  prop: string;
}

export type TParamConsultaOrderForm = {
  [f in keyof IParamConsultaOrder]: FormControl<IParamConsultaOrder[f]> | null;
}
