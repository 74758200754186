import { Component, OnInit, OnDestroy, ViewChild, Injector, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Subject } from 'rxjs';
import { ItensService } from '../../services/itens.service';
import { UnidadesEmpresaParametrosNfceService } from '../../services/unidades-empresa-parametros-nfce.service';
import { mergeArray, fieldRequired, controlRequired } from '../../general/utils';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-combobox-produto',
  templateUrl: './combobox-produto.component.html',
  styleUrls: ['./combobox-produto.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxProdutoComponent),
      multi: true
    }
  ]
})
export class ComboboxProdutoComponent implements OnInit, ControlValueAccessor, OnDestroy {

  ngControl: NgControl;
  disabled = false;
  idProdutoSelecionado = null;
  private objetoProdutoSelecionado = null;
  @ViewChild('select', { static: false }) comboProdutos: NgSelectComponent;
  @Input() label = 'Produto';
  @Input() appendTo = 'body';
  @Input() filtraVendedor = false;
  @Input() exibeAlteraItem = false;
  @Input() exibeClonaItem = false;
  @Input() filtroItensFilhos: number = null;
  @Input() fornecedorCnpj = null;
  valoresPesquisa = [
    { id: 'referenciaItem', label: 'Código' },
    { id: 'nome', label: 'Descrição' },
    { id: 'cEAN', label: 'EAN' },
    { id: 'codigoEntrada', label: 'Código Entrada' },
  ];
  produtos = [];
  produtoInput = new Subject<string>();
  loading: any = {};
  pesquisaSelecionada = "nome"; // Convertido de lista para string na WEB-1824, a pedido do Edson
  @Output() change = new EventEmitter();
  @Output() detalhaItem = new EventEmitter();
  @Output() clonaItem = new EventEmitter();

  constructor(
    private itensService: ItensService,
    private parametrosNfceService: UnidadesEmpresaParametrosNfceService,
    private inj: Injector) { }

  ngOnInit() {
    this.ngControl = this.inj.get(NgControl);
    this.carregaParamConsulta();
    this.onSearchProdutos();
    this.fetchMoreProduto('');
  }

  ngOnDestroy(): void {
    this.parametrosNfceService.putParametrosPesquisaProduto(this.pesquisaSelecionada).toPromise().catch(() => null);
  }

  writeValue(obj: any): void {
    this.idProdutoSelecionado = obj;
    this.carregaidProdutoSelecionado();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => { };

  onTouched = () => { };

  onChange = (event) => {
    const newValue = event && event.id ? event.id : null;
    this.idProdutoSelecionado = newValue;
    this.carregaObjetoProdutoSelecionado(event);
    this.propagateChange(this.idProdutoSelecionado);
    this.change.emit(event);
    this.comboProdutos.close();
  };

  private carregaidProdutoSelecionado() {
    if (this.idProdutoSelecionado && !this.produtos.find(t => t.id === this.idProdutoSelecionado)) {
      this.loading['produto'] = true;
      this.itensService.getItemByIdOData(this.idProdutoSelecionado)
        .pipe(finalize(() => this.loading['produto'] = false))
        .subscribe(res => {
          this.change.emit(res);
          if (res) {
            this.produtos = mergeArray(this.produtos, [res]);
            this.carregaObjetoProdutoSelecionado(res.id);
          }
        });
    }
  }

  carregaParamConsulta() {
    this.loading['paramConsulta'] = true;
    this.parametrosNfceService.getParametroPesquisaProduto()
      .pipe(finalize(() => this.loading['paramConsulta'] = false))
      .subscribe((res: string) => {
        if (!res || (res && res.split(',').length === 0)) {
          this.pesquisaSelecionada = 'nome';
        } else {
          // WEB-1824 quando todos os clientes estiverem com apenas um parâmetro
          // salvo no banco, podemos remover a parte de quebrar a string por vírgula
          this.pesquisaSelecionada = res.split(',')[0];
        }
      }, () => {
        this.pesquisaSelecionada = 'nome';
      });
  }

  fetchMoreProduto(term) {
    this.loading['produtos'] = true;

    this.itensService.getItensComboboxPedido(this.criaFormConsulta(term || ''))
      .pipe(finalize(() => this.loading['produtos'] = false))
      .subscribe(result => {
        this.produtos = mergeArray(this.produtos, result);
      });
  }

  onSearchProdutos() {
    this.produtoInput.pipe(
      debounceTime(500),
      tap(() => this.loading['produtos'] = true),
      switchMap(term => {
        return this.itensService.getItensComboboxPedido(this.criaFormConsulta(term || '', true)).pipe(
          tap(() => this.loading['produtos'] = false)
        )
      })
    )
      .pipe(finalize(() => this.loading['produtos'] = false))
      .subscribe(data => {
        this.produtos = data;

        if (this.produtos && this.produtos.length === 1) {
          this.onChange(this.produtos[0]);
        }
      }, () => {
        this.onSearchProdutos();
      });
  }

  criaFormConsulta(term, novaConsulta = false) {
    let pagina = 1;
    if (!novaConsulta) {
      pagina = Math.trunc(this.produtos.length / 10) + 1
    }

    return {
      searchTerm: term || '',
      filtraVendedor: this.filtraVendedor,
      Codigo: this.verificaFiltro('referenciaItem'),
      Descricao: this.verificaFiltro('nome'),
      EAN: this.verificaFiltro('cEAN'),
      CodigoEntrada: this.verificaFiltro('codigoEntrada'),
      fornecedorCnpj: this.fornecedorCnpj,
      filtroItensFilhos: this.filtroItensFilhos,
      pagina: pagina
    }
  }

  verificaFiltro(nomeFiltro) {
    return this.pesquisaSelecionada === nomeFiltro;
  }

  onClose() {
    if (!this.idProdutoSelecionado) {
      this.produtos = [];
      this.fetchMoreProduto('');
    }
    this.onTouched();
  }

  isParametroSelecionado(op: string): boolean {
    return this.pesquisaSelecionada === op;
  }

  alteraParametroConsulta(param: string) {
    this.pesquisaSelecionada = param;
  }

  get descricaoParametroConsulta() {
    const res = this.valoresPesquisa.find(t => t.id === this.pesquisaSelecionada);
    return res ? res.label : '';
  }

  templateDescricaoItens(item): string {
    const separator = ' - ';
    let retorno = '';
    if (this.pesquisaSelecionada === 'referenciaItem') {
      retorno += item['referenciaItem'] + separator;
    }
    if (this.pesquisaSelecionada === 'cEAN') {
      retorno += item['cEAN'] + separator;
    }
    if (this.pesquisaSelecionada === 'codigoEntrada') {
      retorno += item.itensFornecedores && item.itensFornecedores.length > 0
        ? item.itensFornecedores[0]['referenciaItem'] + separator
        : '';
    }
    retorno += item['nome'];

    return retorno;
  }

  isOnlyEanSelectedAndOneProduct() {
    return this.pesquisaSelecionada === 'cEAN' &&
      this.produtos && this.produtos.length === 1;
  }

  public get objetoSelecionado(): any {
    return this.objetoProdutoSelecionado;
  }

  private carregaObjetoProdutoSelecionado(value) {
    if (value) {
      this.objetoProdutoSelecionado = this.produtos.find(c => c.id === this.idProdutoSelecionado);
    } else {
      this.objetoProdutoSelecionado = null;
    }
  }

  isFieldRequired(): boolean {
    return controlRequired(this.ngControl.control);
  }

  cadastraEditaItem(event) {
    this.detalhaItem.emit(event);
  }

  clonarItem(event, item) {
    // Não selecionar o item na hora de clicar em clonar, para não mostrar erro de falta de estoque.
    event.preventDefault();
    event.stopPropagation();

    this.clonaItem.emit(item);
  }

  onClear() {
    this.produtoInput.next('');
  }
}
