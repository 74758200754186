import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalBasicComponent } from '../../../../shared/modal-basic/modal-basic.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { markFormGroupTouched } from '../../../../general/utils';
import { NfConsumidorService } from '../../../../services/nf-consumidor.service';
import { finalize } from 'rxjs/operators';
import { MessageService } from '../../../../services/message.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-venda-produtos-nfce',
  templateUrl: './venda-produtos-nfce.component.html',
  styleUrls: ['./venda-produtos-nfce.component.css']
})

export class VendaProdutosNfceComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio: ModalBasicComponent;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  impressaoForm: FormGroup;
  loading = false;

  ordenacao = [
    { id: 'ReferenciaItem', name: 'Código produto', },
    { id: 'DescricaoItem', name: 'Descrição produto' },
    { id: 'Quantidade', name: 'Quantidade vendida' },
    { id: 'ValorBruto', name: 'Valor Bruto' },
    { id: 'Desconto', name: 'Desconto' },
    { id: 'ValorLiquido', name: 'Valor Líquido' },
  ];

  constructor(private nfConsumidorService: NfConsumidorService, private message: MessageService) { super() }

  ngOnInit() {
    this.impressaoForm = new FormGroup({
      periodo1: new FormControl(moment().startOf('month').toDate(), [Validators.required]),
      periodo2: new FormControl(moment().endOf('month').startOf('day').toDate(), [Validators.required]),
      clienteId: new FormControl(null),
      order: new FormControl('ReferenciaItem')
    });
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      this.loading = true;
      this.modalRelatorio.hide();
      this.nfConsumidorService.vendasPorProdutoNfce(this.impressaoForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(res => {
          saveAs(res, 'VendasProdutosNFCe.pdf');
          this.destroyComponent();
        }, (error) => {
          this.message.addError({
            title: 'Falha ao gerar relatório.',
            msg: error.errors[0],
            showClose: true, timeout: 20000, theme: 'bootstrap', closeOther: true
          });

          this.destroyComponent();
        });
    }
  }
}
