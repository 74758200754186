import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UnidadesEmpresaParametrosService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getParametros() {
    return this.http
      .get(this.UrlService + 'unidadeEmpresaParametros')
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getSerieAlternativa() {
    return this.http
      .get(this.UrlService + 'unidadeEmpresaParametros/serieAlternativaTitulo')
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getSequenciaSerie(serie) {
    return this.http
      .get(this.UrlService + `unidadeEmpresaParametros/sequenciaSerie/${serie}`)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  postSerieAlternativa(serie) {
    return this.http.post(this.UrlService + 'unidadeEmpresaParametros/postSerieAlternativa', `"${serie}"`)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getParametrosNfe() {
    return this.http
      .get(this.UrlService + 'unidadeEmpresaParametros/parametrosNfe')
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getParametrosGerais() {
    return this.http
      .get(this.UrlService + 'unidadeEmpresaParametros/parametrosGerais')
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getParametrosGeraisPorUnidadeEmpresaID(id: number) {
    return this.http
      .get(this.UrlService + `unidadeEmpresaParametros/parametrosGerais/${id}`)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getParametrosVendas() {
    return this.http
      .get(this.UrlService + 'unidadeEmpresaParametros/parametrosVendas')
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getParametrosFinanceiro() {
    return this.http
      .get(this.UrlService + 'unidadeEmpresaParametros/parametrosFinanceiro')
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getParametrosEstoque() {
    return this.http
      .get(this.UrlService + 'unidadeEmpresaParametros/parametrosEstoque')
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getContasVinculadas(currentUser = null) {
    return this.http
      .post(this.UrlService + 'unidadeEmpresaParametros/contasVinculadas', currentUser)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  obterEmailFiscalEmpresaLogada() {
    return this.http
      .get(this.UrlService + 'unidadeEmpresaParametros/obterEmailFiscalEmpresaLogada')
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  salvarParametrosNfe(form) {
    return this.http.put(this.UrlService + 'unidadeEmpresaParametros/alterarParametrosNFe', form)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  salvarParametrosGerais(form) {
    return this.http.put(this.UrlService + 'unidadeEmpresaParametros/alterarParametrosGerais', form)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  salvarParametrosVendas(form) {
    return this.http.put(this.UrlService + 'unidadeEmpresaParametros/alterarParametrosVendas', form)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  salvarParametrosFinanceiro(form) {
    return this.http.put(this.UrlService + 'unidadeEmpresaParametros/alterarParametrosFinanceiro', form)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  salvarParametrosEstoque(form) {
    return this.http.put(this.UrlService + 'unidadeEmpresaParametros/alterarParametrosEstoque', form)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  baixarBoletoAutomatico() {
    return this.http
      .get(this.UrlService + `unidadeEmpresaParametros/baixarBoletoAutomatico`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
