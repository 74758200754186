import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AliquotasPISCOFINSViewModel } from '../model/AliquotasPISCOFINSViewModel';
import { ConverterNotasFiscaisEntradaItensViewModel } from '../model/ConverterNotasFiscaisEntradaItensViewModel';
import { SalvarServicoViewModel } from '../model/SalvarServicoViewModel';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { ConsultaAutomacaoImpostosViewModel } from '../models/itens/ConsultaItensViewModel';

@Injectable()
export class ItensService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  getById(id: number) {
    return this.http.get(this.UrlService + `Itens/${id}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  uploadFotoProduto(formData: FormData) {
    return this.http.post(this.UrlService + 'Itens/uploadFotoProduto', formData)
        .pipe(map((res: any) => res.data)
            , catchError(super.serviceError));
  }

  baixarFotoProduto(id: any) {
    return this.http.get(this.UrlService + `Itens/baixarFotoProduto/${id}`, { responseType: 'blob'})
      .pipe(map(super.extractData)
        , catchError(super.parseErrorBlob));
  };

  getWithFilters(data) {
    return this.http.post(this.UrlService + 'Itens/buscar', data)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  update(data) {
    return this.http.put(this.UrlService + `Itens/${data.id}`, data)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  imprimir(consulta: any, exibirPreco: boolean, modoDetalhado: boolean, exibirEAN) {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Relatório de produtos',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });
    return this.http
      .post(this.UrlService + `Itens/imprimir?exibirPreco=${exibirPreco}&detalhado=${modoDetalhado}&exibirEAN=${exibirEAN}`,
        consulta, { responseType: 'blob' }).pipe(
          finalize(() => this.message.clear(idToast)), catchError(super.parseErrorBlob));
  }

  relatorioPorOrigem(form: any) {
    return this.http.post(this.UrlService + 'Itens/relatorioPorOrigem', form, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  create(data) {
    return this.http.post(this.UrlService + `Itens`, data)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  delete(id: number) {
    return this.http.delete(this.UrlService + `Itens/${id}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  setPautaSub(item) {
    return this.http.post(this.UrlService + 'ItensPautaEstadosSubstituicaoTributaria', item)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  updatePautaSub(item) {
    return this.http.put(this.UrlService + 'ItensPautaEstadosSubstituicaoTributaria', item)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  removePautaSub(id) {
    return this.http.delete(this.UrlService + `ItensPautaEstadosSubstituicaoTributaria/${id}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPautaSub(item) {
    return this.http.get(this.UrlService + `ItensPautaEstadosSubstituicaoTributaria/${item}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + `Itens/paginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginadoComponentePesquisa(pagina: number, nome: string, param: string = 'Nome') {
    return this.http
      .get(this.UrlService + 'itens/GetPaginadoComponentePesquisa?pageIndex='
        + pagina + '&search=' + encodeURIComponent(nome) + '&param=' + param)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getItensComboboxPedido(data) {
    return this.http.post(this.UrlService + 'Itens/buscarComboboxPedido', data)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getItensComponenteFiltroOData(skip: number, search: string, param: string[] = ['Nome'], pedido = false) {
    const getFilterParam = () => {
      if (param.length === 0) { return ''; }
      let fullFilter = '&$filter=';
      for (let i = 0; i < param.length; i++) {
        let filter = `contains(${param[i]}, '${encodeURIComponent(search)}')`;
        if (param.length > i + 1) { filter += ' OR '; }
        fullFilter += filter;
      }
      return fullFilter;
    };

    // TODO: passar as props por parametro dependendo do que cada tela precisa
    const odataQuery = `?$select=Id, UnidadeMedidaID, AlmoxarifadoPadraoID, PrecoSaida, Nome, ReferenciaItem, cEAN
    &$orderBy=Nome asc&$top=20&$skip=${skip}${getFilterParam()}&$expand=UnidadeMedida`;
    // &$filter=contains(${filtro}, '${encodeURIComponent(valorFiltro)}') and ${filtro} ne '' and ${filtro} ne null

    if (pedido) {
      return this.http.get(this.UrlService + `odata/itens/Pedido${odataQuery}`)
        .pipe(map(super.extractODataResult), catchError(super.serviceError));
    }

    return this.http.get(this.UrlService + `odata/itens${odataQuery}`)
      .pipe(map(super.extractODataResult), catchError(super.serviceError));
  }

  getItemByIdOData(id: number) {
    return this.http.get(this.UrlService + `odata/itens(${id})`)
      .pipe(map(super.extractODataSingleResult), catchError(super.serviceError));
  }

  getByOData(valorFiltro: string) {
    return this.http.get(this.UrlService + `odata/itens${valorFiltro}`)
      .pipe(map(super.extractODataSingleResult), catchError(super.serviceError));
  }

  getPaginadoPorLinhaDeProduto(pagina: number, linhaProdutoID: number) {
    return this.http
      .get(this.UrlService + `Itens/paginadoPorLinhaDeProduto?pageIndex=` + pagina + '&linhaDeProdutoID=' + linhaProdutoID)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginadoPorPedido(consulta) {
    return this.http
      .post(this.UrlService + `Itens/obterDadosValidacao`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  verificaTabelaAutomacao(consulta) {
    return this.http
      .post(this.UrlService + `Itens/verificaTabelaAutomacao`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  consultaAutomacaoImpostos(consulta: ConsultaAutomacaoImpostosViewModel) {
    return this.http
      .post(this.UrlService + `Itens/consultaAutomacaoImpostos`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  consultaAutomacaoFrete(consulta) {
    return this.http
      .post(this.UrlService + `Itens/consultaAutomacaoFrete`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  converterItensFornecedores(cadastro: ConverterNotasFiscaisEntradaItensViewModel) {
    return this.http
      .post(this.UrlService + 'Itens/converterNotasFiscaisEntradaItens', cadastro)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getSequenciaCodigo() {
    return this.http
      .get(this.UrlService + 'Itens/sequenciaCodigo')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  GetByNCMId(classificacao: number, search: string) {
    return this.http
      .get(this.UrlService + `Itens/GetByNCMId/` + classificacao + `/` + search)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  clonarProduto(dados) {
    return this.http
      .post(this.UrlService + 'Itens/ClonarProduto', dados)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  verificaCodigoItem(codigo: string, ignorarId: number): Observable<boolean> {
    let url = this.UrlService + `Itens/verificaCodigoItem?codigo=${encodeURIComponent(codigo)}`;
    if (ignorarId) {
      url += `&ignorarId=${ignorarId}`;
    }

    return this.http
      .get(url)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  alterarDadosFiscais(dados: any) {
    return this.http
      .post(this.UrlService + `Itens/AlterarDadosFiscais`, dados)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  verificarSTItemSelecionado(
    itemId: number,
    tipoNfeId: number,
    clienteId: number,
    clienteFinal: boolean,
    entregaFutura: boolean,
    bonificacaoOuBrinde: boolean,
    transporteForaDoEstado: boolean,
    vendaAmbulante: boolean,
    porContaOrdem: boolean,
    codigoFiscalId: number
  ) {
    const dados = {
      ItemID: itemId,
      ClienteID: clienteId,
      TipoNFeID: tipoNfeId,
      ClienteFinal: clienteFinal,
      EntregaFutura: entregaFutura,
      BonificacaoOuBrinde: bonificacaoOuBrinde,
      TransporteForaDoEstado: transporteForaDoEstado,
      VendaAmbulante: vendaAmbulante,
      PorContaOrdem: porContaOrdem,
      CodigoFiscalId: codigoFiscalId,
    };

    return this.http.post(this.UrlService + `Itens/VerificarSTItemSelecionado`, dados).pipe(
      map((res: any) => res.data),
      catchError(super.serviceError)
    );
  }


  gerarFotosURL(data: any) {
    return this.http.post(this.UrlService + `Itens/gerarFotosURL`, data)
      .pipe(map(super.extractData)
      , catchError(super.serviceError));
  }

  atualizarAbateICMSBasePISCOFINS(id: number, value: boolean) {
    return this.http.post(this.UrlService + `Itens/AtualizarAbateICMSBasePISCOFINS/${id}`, value).pipe(
      map((res: any) => res.data),
      catchError(super.serviceError)
    );
  }

  buscarAliquotasPISCOFINS(): Observable<AliquotasPISCOFINSViewModel> {
    return this.http
      .get(this.UrlService + `Itens/BuscarAliquotasPISCOFINS`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getServicosPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + `Itens/servicosPaginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvarServico(dados: SalvarServicoViewModel) {
    return this.http.post(this.UrlService + 'Itens/SalvarServico', dados)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  alterarDadosFiscaisSemSobrescrever(dados: { itens: any[] }) {
    return this.http
      .post(this.UrlService + `Itens/AlterarDadosFiscaisSemSobrescrever`, dados)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  alterarObservacoesEstoque(dados: { id: any; observacoes: any; }) {
    return this.http
      .post(this.UrlService + `Itens/AlterarObservacoesEstoque`, dados)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
