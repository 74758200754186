import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable()
export class CodigosFiscaisOperacaoService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getPaginado(index: number, search: string) {
        return this.http.get(this.UrlService + `codigosFiscaisOperacao/GetPaginado?index=${index}&search=${search}`)
                .pipe(map((res: any) => res.data),
                catchError(super.serviceError));
    }

    get(codigo: number) {
        return this.http.get(this.UrlService + `codigosFiscaisOperacao?codigo=${codigo}`)
                .pipe(map((res: any) => res.data),
                catchError(super.serviceError));
    }
}
