import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-legenda-status-documentos',
  templateUrl: './legenda-status-documentos.component.html',
  styleUrls: ['./legenda-status-documentos.component.css']
})
export class LegendaStatusDocumentosComponent implements OnInit {

  constructor() { }

  @Input() tipoVisualizacao = 'legenda'; // legenda-detalhe
  @Input() documento: any = null;
  @Input() propStatus = 'status';
  @Input() propCodigoResposta = 'codigoResposta';
  @Input() propDescricaoResposta = 'descricaoResposta';
  @Input() statusOptions =
    [
      { id: 'A', name: 'Aprovada', },
      { id: 'C', name: 'Cancelada' },
      { id: 'F', name: 'Finalizado' },
      { id: 'I', name: 'Inutilizada' },
      { id: 'U', name: 'Rascunho' },
      { id: 'D', name: 'Denegada' },
      { id: 'R', name: 'Reprovada' },
      { id: '0', name: 'Sem retorno' },
    ];

  ngOnInit() {
  }

  getColor(status: string) {
    switch (status) {
      case 'A':
        return 'green';
      case 'C':
        return '#448aff';
      case 'F':
        return '#8A2BE2';
      case 'I':
        return 'orange';
      case 'R':
        return '#ff5252';
      case 'D':
        return '#212020';
      case 'U':
        return '#212020';
      default:
        return 'silver';
    }
  }

  getIcon(status: string) {
    switch (status) {
      case 'U':
        return 'fa fa-pencil';
      case 'A':
        return 'fa fa-check';
      case 'C':
        return 'fa fa-circle';
      case 'F':
        return 'fa fa-check-circle-o';
      case 'I':
        return 'fa fa-circle';
      case 'R':
        return 'fa fa-times';
      case 'D':
        return 'fa fa-legal';
      default:
        return 'fa fa-circle';
    }
  }

  retornaDescStatusDoc(documento): string {

    switch (documento[this.propStatus]) {
      case 'I':
        return 'Numeração Inutilizada';
      case 'C':
        return 'Nota Cancelada';
      case 'D':
        return 'Nota Denegada';
      case 'U':
        return 'Rascunho';
    }

    switch (documento[this.propCodigoResposta]) {
      case '100':
        return 'Nota Aprovada';
      case '999':
        return 'Erro de sistema';
      case '0':
      case '':
      case null:
        return 'Documento não enviado ao SEFAZ';
      default:
        return documento[this.propCodigoResposta] + ' - ' + documento[this.propDescricaoResposta];
    }
  }
}
