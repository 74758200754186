<div class="form-group m-b-0">
  <label [style.font-weight]="isFieldRequired() ? 'bold' : 'none'">{{label}} <small class="ml-1 cursor-pointer">Busca por {{descricaoParametroConsulta}}</small>
    <div [ngStyle]="{'display':loading['paramConsulta'] ? 'none' : 'inline-block' }" ngbDropdown
      #dropDownPesquisa="ngbDropdown" class="btn-group dropdown-split-primary">
      <button type="button" ngbDropdownToggle
        class="btn btn-mini btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light"
        data-toggle="dropdown" aria-haspopup="true" id="dropdownProduto" aria-expanded="false">
      </button>
      <div ngbDropdownMenu placement="bottom-right" class="dropdown-menu" aria-labelledby="dropdownProduto">
        <a ngbDropdownItem class="dropdown-item waves-effect waves-light" *ngFor="let op of valoresPesquisa"
          (click)="alteraParametroConsulta(op.id)">
          <i *ngIf="isParametroSelecionado(op.id)" class="cr-icon icofont icofont-ui-check txt-default"
            style="font-size: 8px; margin-left: -13px"></i>{{op.label}}
        </a>
      </div>
    </div>
  </label>
  <ng-select [appendTo]="appendTo" [disabled]="disabled || loading['paramConsulta']" #select [items]="produtos"
    (open)="dropDownPesquisa.close();" (change)="onChange($event)" [ngModel]="idProdutoSelecionado" (close)="onClose()"
    placeholder="Selecione" [virtualScroll]="true" bindValue="id" (scrollToEnd)="fetchMoreProduto(select.searchTerm)"
    [loading]="loading['produtos'] || loading['produto'] || loading['paramConsulta']" [typeahead]="produtoInput"
    (clear)="onClear()" [editableSearchTerm]="true">

    <ng-template ng-label-tmp let-item="item">
      {{select.loading ? 'Carregando...' : templateDescricaoItens(item)}}
    </ng-template>

    <ng-template ng-option-tmp let-item="item" *ngIf="exibeAlteraItem && exibeClonaItem">
      <div style="display: flex; align-items: center" container="body" [ngbTooltip]="templateDescricaoItens(item)">
        <span class="ng-option-label" style="overflow: hidden; width: 100%; text-overflow: ellipsis">{{templateDescricaoItens(item)}}</span>
        <i (click)="clonarItem($event, item)" style="float: right; font-size: 17px; color: #3F88C5; margin-right: 10px" class="fa fa-copy" title="Clonar"></i>
        <i (click)="cadastraEditaItem(item)" style="float: right; font-size: 17px; color: #3F88C5" class="fa fa-edit" title="Editar"></i>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" *ngIf="exibeAlteraItem && !exibeClonaItem">
      <app-temp-option-item [name]="templateDescricaoItens(item)" (onClick)="cadastraEditaItem(item)" container="body" [ngbTooltip]="templateDescricaoItens(item)">
      </app-temp-option-item>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" *ngIf="!exibeAlteraItem && !exibeClonaItem">
      <span class="ng-option-label" container="body" [ngbTooltip]="templateDescricaoItens(item)">{{templateDescricaoItens(item)}}</span>
    </ng-template>

    <ng-template ng-notfound-tmp let-searchTerm="searchTerm" *ngIf="exibeAlteraItem">
      <app-temp-not-found-item [searchTerm]="searchTerm" (onClick)="cadastraEditaItem(searchTerm)">
      </app-temp-not-found-item>
    </ng-template>

  </ng-select>
  <show-errors [control]="ngControl.control"></show-errors>
</div>
