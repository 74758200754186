<app-modal-basic #modal [dialogClass]="'modal-lg'" (closed)="onClose()">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <i class="modal-title m-r-5"></i> Seleção de Título para OFX
    </h4>
    <button type="button" class="close basic-close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-8 col-xl-8">
          <label>Descrição</label>
          <input class="form-control" placeholder="Descrição da transação" formControlName="descricao" disabled>
        </div>
        <div class="col-xs-12 col-sm-12 col-lg-4 col-xl-4">
          <label>Valor:</label>
          <input class="form-control" placeholder="Valor" formControlName="valorTransacao"
            [options]="{ prefix: 'R$ ', precision: '2' }" currencyMask disabled>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-4 col-xl-4">
          <label>Variação máxima no valor:</label>
          <input class="form-control" placeholder="Variação máxima permitida no valor" formControlName="valor"
            [options]="{ prefix: 'R$ ', precision: '2' }" currencyMask
            [disableControl]="loading['request'] || loading['disable']">
        </div>

        <div class="col-xs-12 col-sm-12 col-lg-4 col-xl-4">
          <label>Data</label>
          <ng-select [items]="filtroData" formControlName="data" placeholder="Selecionar opção" bindLabel="descricao"
            bindValue="id" [disableControl]="loading['request'] || loading['disable']">
          </ng-select>
        </div>

        <div class="col-xs-12 col-sm-12 col-lg-4 col-xl-4">
          <label>&nbsp;</label>
          <button type="submit" class="btn btn-info ripple light formButton"
            [disabled]="loading['request'] || loading['disable']">
            <app-loading [loading]="loading['request'] || loading['disable']" [icone]="'icofont icofont-refresh'">
            </app-loading>
          </button>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
        <div class="table-responsive">
          <div class="datatable-loading" *ngIf="loading['table']">
            <div class="datatable-loader"></div>
          </div>
          <table class="table table-striped table-condensed table-bordered full-data-table" [mfData]="titulos"
            #mf="mfDataTable" [mfRowsOnPage]="10">
            <thead>
              <tr>
                <th>
                  #
                </th>
                <th>
                  <mfDefaultSorter by="numero">Título</mfDefaultSorter>
                </th>
                <th>
                  <mfDefaultSorter by="fornecedor.nome">Fornecedor</mfDefaultSorter>
                </th>
                <th>
                  <mfDefaultSorter by="valorParcela">Valor</mfDefaultSorter>
                </th>
                <th>
                  <mfDefaultSorter by="emissao">Emissão</mfDefaultSorter>
                </th>
                <th>
                  <mfDefaultSorter by="vencimento">Vencimento</mfDefaultSorter>
                </th>
              </tr>
              <tr>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let titulo of mf.data"
                [ngClass]="{'tituloSelecionado': tituloSelecionado && titulo.id == tituloSelecionado.id}">
                <td>
                  <button class="btn btn-info btn-mini" (click)="select(titulo)"
                    [disabled]="loading['request'] || loading['disable']">
                    <i class="icofont icofont-download-alt"> </i>
                  </button>
                  <button class="btn btn-danger btn-mini" (click)="removeSeleted()"
                    *ngIf="tituloSelecionado && titulo.id == tituloSelecionado.id"
                    [disabled]="loading['request'] || loading['disable']">
                    <i class="fa fa-minus"> </i>
                  </button>
                </td>
                <td>{{titulo.numero}}</td>
                <td>{{titulo.fornecedor.nome}}</td>
                <td>{{titulo.valorParcela | currency:'BRL':'symbol':'1.2-2'}}</td>
                <td>{{titulo.emissao | date:'dd/MM/yyyy'}}</td>
                <td>{{titulo.vencimento | date:'dd/MM/yyyy'}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="12">
                  <mfBootstrapPaginator class="pagination-main f-right"></mfBootstrapPaginator>
                  <span>{{titulos.length}} Títulos</span>
                  <button (click)="onClose()" class="btn btn-primary f-right">
                    <i class="icofont icofont-download-alt"></i> Confirmar
                  </button>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-footer">
  </div>
</app-modal-basic>