import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError, forkJoin } from 'rxjs';

@Injectable()
export class DownloadExportacaoService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    exportarNfcs(consulta: any) {
        return this.http
            .post(this.UrlService + 'downloadExportacao/exportarNfc', consulta, { responseType: 'blob' })
            .pipe(catchError(super.parseErrorBlob));
    }

    exportarNfes(consulta: any){
        return this.http
            .post(this.UrlService + 'downloadExportacao/exportarNfe', consulta,)
            .pipe(map((res: any) => res.data));
    }

    enviarNfcsEmail(consulta: any) {
        return this.http.post(this.UrlService + 'downloadExportacao/email', consulta)
            .pipe(map((res: any) => res.data));
    }

    exportarMde(consulta: any){
        return this.http
            .post(this.UrlService + 'downloadExportacao/exportarMde', consulta,)
            .pipe(map((res: any) => res.data));
    }
}
