import { TitulosFornecedoresServiceV2 } from './../services-v2/titulos-fornecedores/titulos-fornecedores-v2.service';
import { CentrosCustosServiceV2 } from './../services-v2/centros-custos/centros-custos-v2.service';
import { UnidadesEmpresaServiceV2 } from './../services-v2/unidades-empresa/unidades-empresa-v2.service';
import { FornecedoresService } from './../services/fornecedores.service';
import { PaisesService } from './../services/paises.service';
import { UsuariosService } from './../services/usuarios.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataTableModule } from '@pascalhonegger/ng-datatable';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { CardRefreshDirective } from './card/card-refresh.directive';
import { CardToggleDirective } from './card/card-toggle.directive';
import { CardComponent } from './card/card.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ParentRemoveDirective } from './elements/parent-remove.directive';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ToastaModule } from 'ngx-toasta';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { LoadingComponent } from './loading/loading.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CnpjcpfPipe } from './pipes/cnpjcpf.pipe';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CustomDatalistComponent } from './custom-datalist/custom-datalist.component';
import { GruposUsuariosService } from '../services/grupos-usuarios.service';
import { DimPaginationComponent } from './dim-pagination/dim-pagination.component';
import { DimDatatableComponent } from './dim-datatable/dim-datatable.component';
import { RouterModule } from '@angular/router';
import { NgxLoadingModule } from 'ngx-loading';
import { UsuarioService } from '../authentication/services/usuario.service';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { DimCurrenyInputComponent } from './dim-curreny-input/dim-curreny-input.component';
import { ShowErrorsComponent } from '../general/validations/show-errors.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { NgHasAccessDirective } from './ng-has-access.directive';
import { ContentEditableDirective } from './contenteditable-model.directive';
import { NgSelectModule, NgSelectConfig } from '@ng-select/ng-select';
import { DisableControlDirective } from './disable-control.directive';
import { DimTabswipeComponent } from './dim-tabswipe/dim-tabswipe.component';
import { DemoMaterialModule } from './material-module';
import { TempNotFoundItemComponent } from './templates/temp-not-found-item/temp-not-found-item.component';
import { TempOptionItemComponent } from './templates/temp-option-item/temp-option-item.component';
import { ModalCadastroComponent } from './modal-cadastro/modal-cadastro.component';
import { TempModalCadastroComponent } from './templates/temp-modal-cadastro/temp-modal-cadastro.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { CadastroPadraoComponent } from './cadastro-padrao/cadastro-padrao.component';
import { SyncControlDirective } from './sync-control.directive';
import { AutofocusDirective } from './auto-focus.directive';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AppDataComponent } from './app-data/app-data.component';
import { ItensService } from '../services/itens.service';
import { UnidadesEmpresaParametrosNfceService } from '../services/unidades-empresa-parametros-nfce.service';
import { ClientesService } from '../services/clientes.service';
import { BaseFactoryComponent } from './base-factory/base-factory.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ClipboardModule } from 'ngx-clipboard';
import { AbrirModalComponent } from './abrir-modal/abrir-modal.component';
import { ComboboxStatusDocumentoModalComponent } from './combobox-status-documento-modal/combobox-status-documento-modal.component';
import { AppTimeComponent } from './app-time/app-time.component';
import { DimContextMenuComponent } from './dim-context-menu/dim-context-menu.component';
import { UnidadesEmpresaParametrosService } from '../services/unidades-empresa-parametros.service';
import { CurrencyFocusDirective } from './currency-focus.directive';
import { NgFloatingActionButtonComponent } from '../shared/fab-button/ng-floating-action-button.component';
import { NgFloatingActionMenuComponent } from '../shared/fab-button/ng-floating-action-menu.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { DragAndDropDirective } from './drag-and-drop.directive';
import { DimCheckboxComponent } from './dim-checkbox/dim-checkbox.component';
import { NgxMaskConfig } from '../config/NgxMaskConfig';
import { DimDataRangeComponent } from './dim-data-range/dim-data-range.component';
import { DimCurrencyRangeComponent } from './dim-currency-range/dim-currency-range.component';
import { DimExportarConsulta } from './dim-exportar-consulta/dim-exportar-consulta.component';
import { DimCheckboxListComponent } from './dim-checkbox-list/dim-checkbox-list.component';
import { DimErrorMessageComponent } from './dim-error-message/dim-error-message.component';
import { DimBintableComponent } from './dim-bintable/dim-bintable.component';
import { AnoFabModPipe } from './pipes/anoFabMod.pipe';
import { ComboboxTipoComissaoComponent } from './combobox-tipo-comissao/combobox-tipo-comissao.component';
import { ComboboxPadraoComponent } from './combobox-padrao/combobox-padrao.component';
import {
  BaseServiceV2,
  TipoComissaoServiceV2,
  TitulosCobrancaServiceV2,
  ClientesServiceV2,
  PedidosServiceV2,
  PortadoresServiceV2,
  ContasCorrentesServiceV2,
  GrupoDespesasServiceV2,
  UnidadesMedidaServiceV2,
  ItensServiceV2,
  NotasFiscaisServiceV2,
} from '@servicesv2';
import { RepresentantesServiceV2 } from '../services-v2/representantes/representantes-v2.service';
import { ComboboxRepresentantesComponent } from './combobox-representantes/combobox-representantes.component';
import { ComboboxPortadoresComponent } from './combobox-portadores/combobox-portadores.component';
import { ComboboxUnidadesMedidaComponent } from './combobox-unidades-medida/combobox-unidades-medida.component';
import { DimInputTextComponent } from './dim-input-text/dim-input-text.component';
import { DimBoldTextDirective } from './directives/dim-bold-text/dim-bold-text.directive';
import { AppFABAtalhosComponent } from './fab-atalhos/fab-atalhos.component';
import { ComboboxCbenefFiscaisComponent } from './combobox-cbenef-fiscais/combobox-cbenef-fiscais.component';
import { CodigosBeneficiosFiscaisServiceV2 } from '../services-v2/codigos-beneficios-fiscais/codigos-beneficios-fiscais-v2.service';
import { UnidadeEmpresaParametrosServiceV2 } from '../services-v2/unidade-empresa-parametros/unidade-empresa-parametros-v2.service';
import { CodigosFiscaisServiceV2 } from './../services-v2/codigos-fiscais/codigos-fiscais-v2.service';

const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  prefix: 'R$ ',
  thousands: '.',
  decimal: ',',
  align: 'right',
  precision: 2,
  suffix: '',
  allowNegative: true
};

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        BsDatepickerModule,
        PaginationModule.forRoot(),
        ToastaModule.forRoot(),
        NgClickOutsideDirective,
        DataTableModule,
        RouterModule,
        NgxLoadingModule,
        AccordionModule.forRoot(),
        DemoMaterialModule,
        NgSelectModule,
        NgScrollbarModule,
        Angular2PromiseButtonModule.forRoot({
            spinnerTpl: '<span class="btn-spinner"><i class="fa fa-circle-o-notch fa-spin"></i></span>',
            disableBtn: true,
            btnLoadingClass: 'is-loading',
            handleCurrentBtnOnly: false,
        }),
        DigitOnlyModule,
        ClipboardModule,
        NgxFileDropModule,
        NgbAccordionModule,
        CurrencyMaskModule,
        NgxMaskDirective,
        NgxMaskPipe,
    ],
    declarations: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        ToggleFullscreenDirective,
        CardRefreshDirective,
        CardToggleDirective,
        ParentRemoveDirective,
        CardComponent,
        SpinnerComponent,
        ModalBasicComponent,
        LoadingComponent,
        TruncatePipe,
        CnpjcpfPipe,
        CustomDatalistComponent,
        DimPaginationComponent,
        DimDatatableComponent,
        DimContextMenuComponent,
        DimCurrenyInputComponent,
        ShowErrorsComponent,
        NgHasAccessDirective,
        ContentEditableDirective,
        DisableControlDirective,
        DimTabswipeComponent,
        TempNotFoundItemComponent,
        TempOptionItemComponent,
        ComboboxStatusDocumentoModalComponent,
        ModalCadastroComponent,
        TempModalCadastroComponent,
        CadastroPadraoComponent,
        SyncControlDirective,
        AutofocusDirective,
        AppDataComponent,
        BaseFactoryComponent,
        AbrirModalComponent,
        AppTimeComponent,
        CurrencyFocusDirective,
        NgFloatingActionButtonComponent,
        NgFloatingActionMenuComponent,
        DragAndDropDirective,
        DimCheckboxComponent,
        DimDataRangeComponent,
        DimCurrencyRangeComponent,
        DimExportarConsulta,
        DimCheckboxListComponent,
        DimErrorMessageComponent,
        DimBintableComponent,
        AnoFabModPipe,
        ComboboxTipoComissaoComponent,
        ComboboxPadraoComponent,
        ComboboxRepresentantesComponent,
        ComboboxPortadoresComponent,
        ComboboxUnidadesMedidaComponent,
        DimInputTextComponent,
        DimBoldTextDirective,
        AppFABAtalhosComponent,
        ComboboxCbenefFiscaisComponent,
    ],
    exports: [
        AccordionAnchorDirective,
        AccordionLinkDirective,
        AccordionDirective,
        ToggleFullscreenDirective,
        CardRefreshDirective,
        CardToggleDirective,
        ParentRemoveDirective,
        CardComponent,
        SpinnerComponent,
        NgbModule,
        PaginationModule,
        FormsModule,
        ReactiveFormsModule,
        ToastaModule,
        ModalBasicComponent,
        LoadingComponent,
        DataTableModule,
        TruncatePipe,
        CnpjcpfPipe,
        AccordionModule,
        CustomDatalistComponent,
        DimPaginationComponent,
        DimDatatableComponent,
        DimContextMenuComponent,
        DimCurrenyInputComponent,
        ShowErrorsComponent,
        DemoMaterialModule,
        NgxLoadingModule,
        Angular2PromiseButtonModule,
        NgHasAccessDirective,
        ContentEditableDirective,
        NgSelectModule,
        DisableControlDirective,
        DimTabswipeComponent,
        TempNotFoundItemComponent,
        TempOptionItemComponent,
        ModalCadastroComponent,
        TempModalCadastroComponent,
        DigitOnlyModule,
        CadastroPadraoComponent,
        SyncControlDirective,
        AutofocusDirective,
        AppDataComponent,
        AbrirModalComponent,
        ComboboxStatusDocumentoModalComponent,
        AppTimeComponent,
        CurrencyFocusDirective,
        CommonModule,
        NgFloatingActionButtonComponent,
        NgFloatingActionMenuComponent,
        NgbAccordionModule,
        DragAndDropDirective,
        DimCheckboxComponent,
        DimDataRangeComponent,
        DimCurrencyRangeComponent,
        DimExportarConsulta,
        DimCheckboxListComponent,
        DimBintableComponent,
        AnoFabModPipe,
        ComboboxTipoComissaoComponent,
        ComboboxRepresentantesComponent,
        ComboboxPortadoresComponent,
        ComboboxUnidadesMedidaComponent,
        DimInputTextComponent,
        DimBoldTextDirective,
        AppFABAtalhosComponent,
        ComboboxCbenefFiscaisComponent,
    ],
    providers: [
        UsuariosService,
        PaisesService,
        FornecedoresService,
        GruposUsuariosService,
        UnidadesEmpresaParametrosNfceService,
        UnidadesEmpresaParametrosService,
        ItensService,
        ClientesService,
        UsuarioService,
        BsDatepickerConfig,
        BaseServiceV2,
        TipoComissaoServiceV2,
        TitulosCobrancaServiceV2,
        ClientesServiceV2,
        PedidosServiceV2,
        RepresentantesServiceV2,
        PortadoresServiceV2,
        ContasCorrentesServiceV2,
        UnidadesEmpresaServiceV2,
        GrupoDespesasServiceV2,
        CentrosCustosServiceV2,
        TitulosFornecedoresServiceV2,
        UnidadesMedidaServiceV2,
        CodigosBeneficiosFiscaisServiceV2,
        ItensServiceV2,
        NotasFiscaisServiceV2,
        UnidadeEmpresaParametrosServiceV2,
        CodigosFiscaisServiceV2,
        {
            provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig
        },
        provideNgxMask(NgxMaskConfig),
    ],
})

export class SharedModule {
  constructor(private config: NgSelectConfig) {
    this.config.notFoundText = 'Item não encontrado';
    this.config.typeToSearchText = 'Digite para pesquisar';
    this.config.loadingText = 'Carregando';
    this.config.clearAllText = 'Limpar';
  }
}
