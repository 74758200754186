import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-relatorio-produtos',
  templateUrl: './relatorio-produtos.component.html',
  styleUrls: ['./relatorio-produtos.component.css']
})
export class RelatorioProdutosComponent extends ModalCadastroComponent implements OnInit {

  constructor() { super(); }

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  form: FormGroup;

  ngOnInit() {
    this.form = new FormGroup({
      ocultarPrecoVenda: new FormControl(false),
      modoDetalhado: new FormControl(false),
      exibirCodigoEAN: new FormControl(false),
    });
  }

  show() {
    this.modalRelatorio.show();
  }

  enviarConfig() {
    this.closed.emit(this.form.value);
  }

  destroyComponent() {
    this.closed.emit();
  }
}
