<app-modal-basic #modal (closed)="handleOnClose($event)">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <i class="modal-title icofont icofont-file-alt m-r-5"></i>
      <span #title>
        <ng-content select="[title]"></ng-content>
      </span>
      <ng-container *ngIf="!title.hasChildNodes()">Exportar</ng-container>
    </h4>
    <button type="button" class="close basic-close" (click)="onCancel($event)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <ng-content select="[body=header]"></ng-content>
    <div class="row">
      <div class="col-12">
        <label>Selecione o tipo de arquivo para exportar:</label>
        <app-dim-checkbox-list [formGroup]="exportFileTypeForm" [formLabels]="exportFileTypeLabels"
          [minimoSelecionado]="1" mensagemErro="Selecione um tipo de arquivo para exportar." [selecaoUnica]="true"
          ></app-dim-checkbox-list>
      </div>
    </div>
    <ng-content select="[body=between]"></ng-content>
    <div class="row" *ngIf="exportFileTypeForm.get('pdf').value">
      <div class="col-12">
        <label>Selecione as colunas que deseja exportar:</label>
        <app-dim-checkbox-list [formGroup]="form" [formLabels]="formLabels"
          [validacao]="validacao" [mostrarErro]="mostrarErro" [colSize]="colSize"
          ></app-dim-checkbox-list>
      </div>
    </div>
    <ng-content select="[body=footer]"></ng-content>
    <div class="row">
      <div class="col-12">
        <button type="button" class="btn btn-primary ripple light" (click)="onExport($event)"
          [disabled]="!form.valid || !exportFileTypeForm.valid || exportando">
          <app-loading icone="icofont icofont-print" [loading]="exportando"
            [texto]="exportarLabel" [textoLoading]="exportandoLabel">
          </app-loading>
        </button>
        <button type="button" class="btn btn-danger ripple" (click)="onCancel($event)">
          <i class="ti ti-close"></i> {{ cancelarLabel }}
        </button>
      </div>
    </div>
  </div>
  <div class="app-modal-footer"></div>
</app-modal-basic>
