<form [formGroup]="form">
  <span #dimCurrencyRangeLabel>
    <ng-content select="label"></ng-content>
  </span>
  <ng-container *ngIf="!dimCurrencyRangeLabel.hasChildNodes()">
    <label>Valor</label>
  </ng-container>
  <div class="input-group">
    <input autoselect type="text" class="form-control"
      currencyMask [formControlName]="controlNameMin" [disableControl]="disabled"/>
    <span class="input-group-addon">até</span>
    <input autoselect type="text" class="form-control"
      currencyMask [formControlName]="controlNameMax" [disableControl]="disabled"/>
  </div>
  <show-errors *ngIf="mostrarErro" [control]="form.get(controlNameMin)"></show-errors>
</form>
