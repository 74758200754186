import { KeyValue } from '@angular/common';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-dim-checkbox-list',
  templateUrl: './dim-checkbox-list.component.html',
  styleUrls: ['./dim-checkbox-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DimCheckboxListComponent),
      multi: true
    }
  ]
})
export class DimCheckboxListComponent implements ControlValueAccessor, OnInit {

  constructor(public controlContainer: ControlContainer) { }
  private onChanged = (_: any) => { return; };
  private onTouched = (_: any) => { return; };
  writeValue(_: any): void { return; }
  registerOnChange(_: any): void { this.onChanged = _; }
  registerOnTouched(_: any): void { this.onTouched = _; }

  ngOnInit() {
    if (this.validacao) {
      this.form.setValidators([this.validateMinimumSelected()]);
    }
  }

  @Input() validacao = true;
  @Input() selecaoUnica = false;
  @Input() mostrarErro = true;

  @Input() minimoSelecionado = 2; // Cuidar quando mudar esta quantidade. Pode precisar mudar a mensagemErro também.
  @Input() mensagemErro = 'Selecione pelo menos duas colunas.';

  @Input() formLabels = {};
  @Input() colSize = 'col-6';

  /**
   * Validador personalizado para verificar se um número mínimo de checkboxes em um FormGroup está selecionado.
   *
   * @returns {ValidatorFn} Uma função validadora que retorna `ValidationErrors` se a validação falhar, ou `null` se a validação passar.
   *
   * @description
   * Este método privado cria um validador que verifica se pelo menos `minimoSelecionado` checkboxes estão marcados em um `FormGroup`.
   * Se a quantidade de checkboxes selecionados for menor que o mínimo, um erro é atribuído ao primeiro controle do formulário.
   *
   * @remarks
   * - A validação só é executada se a propriedade Input `validacao` do componente for verdadeira.
   * - **Importante:** Para garantir que a validação seja reavaliada após mudança na propriedade Input `validacao`,
   *    utilize o método `updateValueAndValidity` do `FormGroup` após a alteração.
   *    Mais informações: https://v17.angular.io/api/forms/AbstractControl#updateValueAndValidity
   */
  private validateMinimumSelected(): ValidatorFn {
    return (fg: FormGroup): ValidationErrors => {
      if (this.minimoSelecionado === 0) {
        return;
      }

      const { values, keys, firstFormControl } = this.formMetadata(fg);
      if (!keys || !keys.length || !firstFormControl) {
        return;
      }

      firstFormControl.setErrors(null);
      const checkboxes: boolean[] = keys.map(k => values[k]);
      const selected: boolean[] = checkboxes.filter(c => c);
      if (selected.length < this.minimoSelecionado) {
        firstFormControl.setErrors({ custom: { message: this.mensagemErro }})
      }

      return;
    }
  }

  /**
   * Extrai metadados de um FormGroup, incluindo valores, chaves e o primeiro FormControl.
   *
   * @param {FormGroup} fg - O FormGroup do qual os metadados serão extraídos.
   * @returns {FormMetadata} Um objeto contendo os metadados do FormGroup.
   *
   * @remarks
   * - Este método assume que o `FormGroup` possui pelo menos um `FormControl`. Se o formulário estiver vazio, `firstFormControl` será `null`.
   * - O tipo `any` é utilizado para `values` devido à natureza dinâmica dos valores dos controles do formulário.
   */
  private formMetadata(fg: FormGroup): FormMetadata {
    const values: any = fg.value;
    const keys: string[] = Object.keys(values);
    const first: string = keys[0] || '';
    const firstFormControl = fg.get(first) as FormControl;
    return { values, keys, firstFormControl };
  }

  get form() {
    const fg = this.controlContainer.control as FormGroup;
    return fg;
  }

  /**
   * Utilizado no template para renderizar a mensagem de erro definida na propriedade Input `mensagemErro`
   */
  get firstFormControl() {
    return this.formMetadata(this.form).firstFormControl;
  }

  /**
   * Manipula a mudança de estado de um checkbox em um grupo, garantindo seleção única se ativado.
   *
   * @param {ControlKeyValue} ckv - ControlKeyValue representa o checkbox alterado.
   *
   * @description
   * Este método é acionado pelo evento `(change)` do componente dim-checkbox.
   * Se a propriedade Input `selecaoUnica` estiver ativa, e o checkbox for marcado, todos os outros checkboxes do FormGroup serão desmarcados.
   */
  handleOnChanged(ckv: ControlKeyValue): void {
    if (!this.selecaoUnica) {
      return;
    }

    const { key, value: { value: checked } } = ckv;
    const { keys } = this.formMetadata(this.form);
    if (keys.length && checked) {
      keys.filter(k => k != key)
        .forEach(k => this.form.get(k).setValue(false));
    }
  }

  getControlLabel(ckv: ControlKeyValue): string {
    return this.formLabels[ckv.key] || 'Não encontrado';
  }

  /**
   * Função de comparação para manter a ordem original das chaves em um objeto.
   *
   * @param {KeyValue<number, string>} a - O primeiro par chave-valor a ser comparado.
   * @param {KeyValue<number, string>} b - O segundo par chave-valor a ser comparado.
   * @returns {number} Sempre retorna 0, indicando que a ordem original deve ser mantida.
   *
   * @description
   * Esta função é projetada para ser usada como argumento do pipe `keyvalue` do Angular em um `ngFor`.
   * O pipe `keyvalue` ordena as chaves de um objeto por padrão, mas ao passar esta função como argumento, a ordem original das chaves é preservada.
   *
   * @remarks
   * - A função sempre retorna 0, o que indica ao pipe `keyvalue` que os elementos não devem ser reordenados.
   * - O tipo `KeyValue` é uma interface genérica do Angular que representa um par chave-valor.
   */
  originalOrder(a: KeyValue<number,string>, b: KeyValue<number,string>): number {
    return 0;
  }
}

/**
 * @property {any} values - Um objeto contendo os valores de todos os controles do formulário.
 * @property {string[]} keys - Um array com os nomes (strings) de todos os controles do formulário.
 * @property {FormControl | null} firstFormControl - O primeiro `FormControl` encontrado no formulário, ou `null` se o formulário estiver vazio.
 */
interface FormMetadata {
  values: any;
  keys: string[];
  firstFormControl: FormControl | null;
}

interface ControlKeyValue {
  key: string;
  value: FormControl;
}
