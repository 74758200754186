import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'underscore';
import { isCNPJ, isCPF } from 'brazilian-values';
import { map, pairwise, startWith } from 'rxjs/operators';
import Decimal from 'decimal.js';
import * as saveAs from 'file-saver';

/**
   - Os seguintes caracteres não serão permitidos { < > ' " / \ ; ( ) [ ] , = }.
   - Precisa ter pelo menos uma letra (maiúscula ou minúscula) ou um número.
   - Precisa ter no mínimo 8 caracteres e no máximo 20 (limite definido no BD).
*/
export const regexValidacaoSenha = new RegExp("^(?!.*[<>'\"/\\;,()\\[\\]=])^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$");

export function dyanmicDownloadByHtmlTag(arg: {
  fileName: string,
  text: string
}) {
  const setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  };

  if (!setting.element.dynamicDownload) {
    setting.element.dynamicDownload = document.createElement('a');
  }
  const element = setting.element.dynamicDownload;
  const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
  element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
  element.setAttribute('download', arg.fileName);

  const event = new MouseEvent('click');
  element.dispatchEvent(event);
}

export function toCamel(o) {
  let newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(val => {
      if (typeof val === 'object') {
        val = toCamel(val);
      }
      return val;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
        value = o[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
}

export function mapResponse(array, id, name) {
  return array.map(valor => {
    return { id: valor[id], name: valor[name] };
  });
}

export function markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach(control => {

    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}

export function getFormValidationErrors(formGroup: FormGroup) {
  const errors = [];
  if (formGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      const control: any = formGroup.controls[key];
      if (control.controls) {
        getFormValidationErrors(control);
      }
      if (control.errors) {
        errors.push(key);
      }
    });
  }
  return errors;
}

export function disableFieldsForm(formGroup: FormGroup, disable: boolean) {
  (<any>Object).values(formGroup.controls).forEach(control => {

    disable ? control.disable() : control.enable();

    if (control.controls) {
      disableFieldsForm(control, disable);
    }
  });
}

export function setFormValue(formGroup: FormGroup, value, copyId: boolean = true, copyFormArray: boolean = true) {
  Object.keys(formGroup.controls).forEach(key => {
    if (formGroup.get(key) instanceof FormGroup) {
      if (value[key]) {
        setFormValue(formGroup.get(key) as FormGroup, value[key], copyId, copyFormArray);
      }
    } else if (formGroup.get(key) instanceof FormArray) {
      if (!copyFormArray) {
        return;
      }
      setArrayFormValue(formGroup.get(key) as FormArray, value[key] as Array<any> || [], copyId);
    } else {
      if (key === 'id' && !copyId) {
        return;
      }
      formGroup.get(key).setValue(value[key]);
    }
  });
}

export function setFormValueAsync(formGroup: FormGroup, value, copyId: boolean = true, copyFormArray: boolean = true) {
  return new Promise((resolve, reject) => {
    Object.keys(formGroup.controls).forEach((key, i, arr) => {
      if (formGroup.get(key) instanceof FormGroup) {
        if (value[key]) {
          setFormValue(formGroup.get(key) as FormGroup, value[key], copyId, copyFormArray);
        }
      } else if (formGroup.get(key) instanceof FormArray) {
        if (!copyFormArray) {
          return;
        }
        setArrayFormValue(formGroup.get(key) as FormArray, value[key] as Array<any> || [], copyId);
      } else {
        if (key === 'id' && !copyId) {
          return;
        }
        formGroup.get(key).setValue(value[key]);
      }

      if (i === (arr.length - 1)) {
        setTimeout(resolve);
      }
    });
  });
}

export function setArrayFormValue(formArray: FormArray, array, copyId: boolean = true) {
  array.forEach(val => {
    if (!copyId) {
      val.id = null;
    }
    formArray.insert(0, new FormControl(val));
  });
}

export function setFieldFormValue(formGroup: FormGroup, key, value) {
  formGroup.get(key).setValue(value);
}


export function getDeviceType() {
  const windowWidth = window.innerWidth;

  if (windowWidth >= 768 && windowWidth <= 1024) {
    return 'tablet';
  } else if (windowWidth < 768) {
    return 'mobile';
  } else {
    return 'desktop';
  }

}

export function getOriantation() {
  if (window.matchMedia('(orientation: landscape)').matches) {
    return 'landscape';
  } else {
    return 'portrait';
  }
}

export function fieldRequired(formgroup: FormGroup, field) {
  if (formgroup.get(field).validator) {
    const validador = formgroup.get(field).validator({} as AbstractControl);
    return validador && validador.required ? true : false;
  }
  return false;
}

export function controlRequired(control: AbstractControl) {
  if (control.validator) {
    const validador = control.validator({} as AbstractControl);
    return validador && validador.required ? true : false;
  }
  return false;
}

export function selectAllSelect(form, formControl, options): void {
  form.get(formControl).setValue(
    options.map(x => x.id)
  );
}

export function cleanSelect(form, formControl): void {
  form.get(formControl).setValue([]);
}

export function mergeArray(array1, array2 = []): [] {
  return _.uniq(_.union(array1, array2), false, (item) => item.id);
}

export function mergeArrays(arrays: any): any[] {
  if (!Array.isArray(arrays)) {
    return [];
  }

  let ret = [];
  for (const arr of arrays) {
    ret = _.union(ret, arr);
  }
  return ret;
}

export function calcularData(data: Date, dias = 0, meses = 0, anos = 0): Date {
  const datan = new Date(data);
  datan.setFullYear(datan.getFullYear() + anos);
  datan.setMonth(datan.getMonth() + meses);
  datan.setDate(datan.getDate() + dias);
  return datan;
}

export function validarCPF(cpf) {
  return isCPF(cpf);
}

export function validarCNPJ(cnpj: string): boolean {
  return isCNPJ(cnpj);
}

export function convertB64ToBlob(b64Data, contentType): Blob {
  var byteCharacters = atob(b64Data);
  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var blob = new Blob([byteArray], { type: contentType });
  return blob;
}


const countDecimal = (num) => num.toString().split('.')[1] ? num.toString().split('.')[1].length : 0;

export function roundToEven(n, d = 2) {
  if (!n) {
    return 0;
  }
  if (d < 0) {
    throw Error('O valor de casas decimais deve ser maior que 0.');
  }
  n = Number(n).toFixed(8);

  for (let i = countDecimal(n); i > d; i--) {
    const r = Math.floor(n * Math.pow(10, i - 2)) / Math.pow(10, i - 2);

    const f = Number((n - r).toFixed(i)) * Math.pow(10, i - 1);
    const first = Math.round(f - (f % 1));
    const second = Math.round((f % 1) * 10);
    let nn;

    if ((i) === (d + 1)) {
      if (second === 5) {
        nn = first % 2 !== 0 ? Math.round(f) : Math.floor(f);
      } else {
        nn = Math.round(f);
      }
    } else {
      if (first === 5) {
        nn = second >= 5 ? Math.floor(f + 1) : Math.floor(f);
      } else {
        nn = Math.floor(f);
      }
    }

    n = Number((r + (nn / Math.pow(10, i - 1))).toFixed(i - 1));
  }

  return n;
}

export function intToHour(horario) {
  const hora = Math.floor(horario / 60);
  const minutos = ((horario / 60 % 1) * 60).toFixed(0);
  return hora.toString().padStart(2, '0') + ':' + minutos.toString().padStart(2, '0');
}

export interface ChangeResponse {
  propertyName: string;
  oldValue: any;
  newValue: any;
}

export function valueChangeDetection(form: FormGroup, controls: string[], callback: ((arg0: ChangeResponse) => void), firstAutoExecute?: boolean) {
  if (form) {
    controls.forEach(control => {
      const formControl = form.get(control) || null;
      if (formControl) {
        formControl.valueChanges
          .pipe(startWith(null), pairwise())
          .pipe(map(x => {
            return {
              oldValue: x[0],
              newValue: x[1],
              propertyName: control
            } as ChangeResponse;
          }))
          .subscribe(callback);
      }
    });
  }

  if (firstAutoExecute) {
    callback(null);
  }
}

// Edson:
// Se a terceira casa for 0 a 4, manter a segunda casa, arredondar para baixo.
// Se a terceira casa for de 5 a 9, somar 1 na segunda casa, arredondar para cima.
export function roundCorrectly(val, casas): Decimal {
  return new Decimal(val).toDecimalPlaces(casas, Decimal.ROUND_HALF_UP);
}
/**
 * @param element Id do elemento
 * @ Exemplo: scrollIntoView('consulta');
 */
export function scrollIntoView(element: string): void {
  const topNavbarHeight = 66; // 56 (tamanho) + 10 (margem)
  setTimeout(() => {
    const y = document.getElementById(element);
    if (y) {
      const t = y.getBoundingClientRect().top + window.scrollY - topNavbarHeight;
      window.scroll({ top: t, behavior: 'smooth' });
    }
  }, 10);
}

/**
 * Remove caracteres fora do range 32-255 da tabela ASCII, para ficar compatível com o tipo TString do schema NFe.
 *
 * Remove alguns caracteres que não são impressos em tela mas que geravam problema na DANFE e geram problemas nas consultas por nome por exemplo.
 *
 * Remove espaços no início e fim da string.
 * @param str
 */
export function tratarStringXml(str: string): string {
  return str.replace(/[^\x20-\xFF]/gm, '')
    .replace(/[\xA0\xAD]/gm, '')
    .trim();
}

/**
 * Retorna a primeira mensagem de erro encontrada.
 * Útil para mostrar a mensagem para o usuário após uma chamada à API por exemplo.
 *
 * Considera os seguintes erros:
 * - backend: throw new Exception("teste");
 * - backend: NotificarErro("teste"); return await Response();
 * - frontend: throw new Error("teste");
 * - frontend: Falha de conexão com o servidor.
 * - frontend: Falha na requisição com catchError(super.parseErrorBlob).
 *
 * Exemplo de uso:
 * ```
 * try {
 *   await this.clienteService.buscarCliente(id).toPromise();
 * } catch (err) {
 *   this.message.error('Falha ao buscar cliente: ' + getErrorMessage(err));
 * }
 * ```
 *
 * @param err Error
 */
export function getErrorMessage(err: any) : string {
  if (err.error?.errors) {
    return err.error.errors[0];
  } else if (err.msg?.errors) {
    return err.msg.errors[0];
  } else if (err.errors) {
    return err.errors[0];
  } else {
    return err.message;
  }
}

/**
 * Retorna o arquivo em string base64, no formato "data:mimetype;base64,AAAAAAAAA...".
 *
 * @param file Arquivo para converter, vindo do input type="file"
 */
export function fileToBase64(file: File): Promise<string> {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      res(reader.result as string);
    };
    reader.onerror = rej;
  });
}

/**
 * Salva uma Blob em um arquivo.
 * Extensão do arquivo é configurada automaticamente.
 *
 * Suporta arquivos PDF, XLSX, XML e ZIP.
 *
 * @param blob Blob
 * @param fileName Nome do arquivo a ser gerado
 */
export function saveBlobToFile(blob: Blob, fileName: string = "arquivo"): Promise<boolean> {
  return new Promise((resolve, reject) => {
    if (!blob || !blob.size) {
      reject(false);
      return;
    }

    const [ _, typeSplit ] = blob.type.split("/");
    const type = typeSplit.indexOf(".") == -1 ? typeSplit : typeSplit.split(".").pop();
    if (["pdf", "xml", "zip", "sheet"].includes(type)) {
      const fileType = type == "sheet" ? "xlsx" : type;
      saveAs(blob, `${fileName}.${fileType}`);
      resolve(true);
    } else {
      reject(false);
    }
  });
}

export const DateUtils = {
  nextBusinessDay: (date: Date) => {
    const d = new Date(date);
    while ([6, 0].includes(d.getDate())) {
      d.setDate(d.getDate() + 1);
    }
    return d;
  },
  previousBusinessDay: (date: Date) => {
    const d = new Date(date);
    while ([6, 0].includes(d.getDate())) {
      d.setDate(d.getDate() - 1);
    }
    return d;
  }
}
