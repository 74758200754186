<app-temp-modal-cadastro #modalReplicacao (closed)="closed.emit(false)" [textHeader]="'Parcelamento'" customClass="modal-lg">

  <div class="row">
    <div class="form-group col-md-6 col-sm-6 col-xs-12 pull-left">
      <span> Primeiro vencimento </span>
      <app-data [disabled]="loading['request']" [(ngModel)]="primeiroVencimento"></app-data>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-6 col-sm-6 col-xs-12 pull-left">
      <span> Número de parcelas </span>
      <input [disabled]="loading['request']" class="form-control" digitOnly #quantidade value="2">
    </div>

    <div class="form-group col-md-6 col-sm-6 col-xs-12 pull-left button-form">
      <button type="button" (click)="gerarParcelas(quantidade.value)" class="btn btn-primary">
        Gerar parcelas
      </button>
    </div>
    <div *ngIf="parcelamentos && parcelamentos.length > 0" class="table-responsive table-parcelamento">
      <table class="table">
        <thead>
          <tr>
            <th [ngClass]="ocultarEmTelaMenor">Série</th>
            <th [ngClass]="ocultarEmTelaMenor">Número</th>
            <th>Parcela</th>
            <th>Vencimento</th>
            <th>Valor</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of parcelamentos; let id = index">
            <td [ngClass]="ocultarEmTelaMenor">{{item.serie}}</td>
            <td [ngClass]="ocultarEmTelaMenor">{{item.numero}}</td>
            <td>{{item.parcela}}</td>
            <td>
              <app-data [(ngModel)]="item.vencimento" calendarPlacement="{{isLastItem(parcelamentos.length, id)}}"></app-data>
            </td>
            <td>
              <input class="form-control" type="text"
                currencyMask
                [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2 }"
                [(ngModel)]="item.valorParcela">
            </td>
          </tr>

        </tbody>

      </table>

    </div>
  </div>

  <div *ngIf="parcelamentos && parcelamentos.length > 0" class="btnFooter">
    <button type="button" class="btn btn-primary ripple light" (click)="salvarTitulos()"
      [disabled]="loading['request']">Salvar</button>
    <button type="button" class="btn btn-default ripple" (click)="modalReplicacao.hide()">Cancelar</button>
  </div>

  <div class="alert alert-danger" *ngIf="errors && errors.length > 0">
    <span>Falha ao realizar operação!</span>
  </div>

</app-temp-modal-cadastro>