<app-temp-modal-cadastro #modalCadastro modalContentId="modal-alterar-dados-fiscais" (closed)="onClose()" textHeader="Alterar dados fiscais" customClass="modal-lg">
  <form [formGroup]="cadastroForm" (ngSubmit)="submit()">
    <div *ngIf="cadastroForm.value.modo === 'produto'">
      <p>O produto selecionado não possui as configurações necessárias para uso em notas de venda.<br>
        Para configurar o produto preencha as informações a seguir:</p>
      <div class="row">
        <div class="col-md-12 col-lg-8 form-group">
          <label>Tipo de Produto</label>
          <ng-select appendTo="#modal-alterar-dados-fiscais"  [loading]="!tipoItens" formControlName="tipoItemID"
            placeholder="Selecione" [items]="tipoItens" [disableControl]="!tipoItens" bindLabel="descricao" bindValue="id">
          </ng-select>
          <show-errors [control]="cadastroForm.controls.tipoItemID"></show-errors>
        </div>
        <div class="col-md-12 col-lg-4 form-group">
          <label>Alíquota ICMS<span *ngIf="estadoEmpresa"> para {{estadoEmpresa}}</span></label>
          <ng-select appendTo="#modal-alterar-dados-fiscais" [loading]="!aliquotasICMS" formControlName="aliquotaICMSID"
           placeholder="Selecione" [items]="aliquotasICMS" [disableControl]="!aliquotasICMS" bindLabel="aliquotaICMS" bindValue="id">

            <ng-template ng-label-tmp let-item="item">
              {{aliquotaIcmsSelectLabel(item)}}
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              {{aliquotaIcmsSelectLabel(item)}}
            </ng-template>

            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
              <app-temp-not-found-item [searchTerm]="searchTerm"
                (onClick)="abrirCadastroAliquota(searchTerm)">
              </app-temp-not-found-item>
            </ng-template>

          </ng-select>
          <show-errors [control]="cadastroForm.controls.aliquotaICMSID"></show-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 form-group">
          <label>Origem Mercadoria</label>
          <ng-select appendTo="#modal-alterar-dados-fiscais" [loading]="!origens" formControlName="origemMercadoriaID"
            placeholder="Selecione" [items]="origens" [disableControl]="!origens" bindLabel="descricao" bindValue="id">
          </ng-select>
          <show-errors [control]="cadastroForm.controls.origemMercadoriaID"></show-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-lg-4 form-group">
          <div checkbox class="checkbox-fade fade-in-default">
            <label>
                <input type="checkbox" formControlName="substituicaoTributaria" (change)="changedSubstituicaoTributaria($event)">
                <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
                <span class="text-inverse">Sujeito Subs. Tributária</span>
            </label>
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 form-group">
          <div checkbox class="checkbox-fade fade-in-default">
            <label>
                <input type="checkbox" formControlName="isentoIcms" (change)="changedIsentoIcms($event)">
                <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
                <span class="text-inverse">Isento de ICMS</span>
            </label>
          </div>
        </div>
        <div class="col-sm-12 col-lg-4 form-group">
          <div checkbox class="checkbox-fade fade-in-default">
            <label>
                <input type="checkbox" formControlName="abaterICMSBasePISCOFINS">
                <span class="cr">
                    <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
                <span class="text-inverse">Abater ICMS Base PIS/COFINS</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-8 form-group">
          <label>CST IPI</label>
          <ng-select appendTo="#modal-alterar-dados-fiscais" [items]="cstIPI" bindLabel="descricao" bindValue="tipo"
            placeholder="Selecione" formControlName="situacaoTributariaIPI"></ng-select>
            <show-errors [control]="cadastroForm.controls.situacaoTributariaIPI"></show-errors>
        </div>
        <div class="col-md-12 col-lg-4 form-group">
          <label>Alíquota IPI</label>
          <input type="text" currencyMask [options]="{prefix: ''}" class="form-control"
            placeholder="Alíquota IPI" formControlName="aliquotaIPI">
          <show-errors [control]="cadastroForm.controls.aliquotaIPI"></show-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-8 form-group">
          <label>CST PIS</label>
          <ng-select appendTo="#modal-alterar-dados-fiscais" [items]="cstPIS" bindLabel="descricao" bindValue="tipo"
            placeholder="Selecione" formControlName="situacaoTributariaPIS" (change)="changedCstPisCofins('aliquotaPIS', $event)">
          </ng-select>
          <show-errors [control]="cadastroForm.controls.situacaoTributariaPIS"></show-errors>
        </div>
        <div class="col-md-12 col-lg-4 form-group">
          <label>Alíquota PIS</label>
          <input type="text" currencyMask [options]="{prefix: ''}" class="form-control"
            placeholder="Alíquota PIS" formControlName="aliquotaPIS" [disableControl]="loading">
          <show-errors [control]="cadastroForm.controls.aliquotaPIS"></show-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-8 form-group">
          <label>CST COFINS</label>
          <ng-select appendTo="#modal-alterar-dados-fiscais" [items]="cstCOFINS" bindLabel="descricao" bindValue="tipo"
            placeholder="Selecione" formControlName="situacaoTributariaCOFINS" (change)="changedCstPisCofins('aliquotaCOFINS', $event)">
          </ng-select>
          <show-errors [control]="cadastroForm.controls.situacaoTributariaCOFINS"></show-errors>
        </div>
        <div class="col-md-12 col-lg-4 form-group">
          <label>Alíquota COFINS</label>
          <input type="text" currencyMask [options]="{prefix: ''}" class="form-control"
            placeholder="Alíquota COFINS" formControlName="aliquotaCOFINS" [disableControl]="loading">
          <show-errors [control]="cadastroForm.controls.aliquotaCOFINS"></show-errors>
        </div>
      </div>
    </div>
    <div *ngIf="cadastroForm.value.modo === 'cfop'">
      <p>O CFOP {{cadastroForm.value.cfopCodigo}} não possui as configurações necessárias para essa operação.<br>
        Para configurar o CFOP preencha as informações a seguir:</p>
      <div class="row">
        <div class="col-sm-6 form-group" *ngIf="!cfopOriginadaEncomendaFutura">
          <label>CST/CSOSN ICMS</label>
          <ng-select appendTo="#modal-alterar-dados-fiscais" [items]="cstICMS" bindLabel="descricao" bindValue="tipo"
            placeholder="Selecione" formControlName="situacaoTributariaICMS"></ng-select>
          <show-errors [control]="cadastroForm.controls.situacaoTributariaICMS"></show-errors>
        </div>
        <div class="form-group" [ngClass]="cfopOriginadaEncomendaFutura ? 'col-sm-12' : 'col-sm-6'">
          <label>CST IPI</label>
          <ng-select appendTo="#modal-alterar-dados-fiscais" [items]="cstIPI" bindLabel="descricao" bindValue="tipo"
            placeholder="Selecione" formControlName="situacaoTributariaIPI"></ng-select>
          <show-errors [control]="cadastroForm.controls.situacaoTributariaIPI"></show-errors>
        </div>
        <div class="col-sm-6 form-group">
          <label>CST PIS</label>
          <ng-select appendTo="#modal-alterar-dados-fiscais" [items]="cstPIS" bindLabel="descricao" bindValue="tipo"
            placeholder="Selecione" formControlName="situacaoTributariaPIS"></ng-select>
          <show-errors [control]="cadastroForm.controls.situacaoTributariaPIS"></show-errors>
        </div>
        <div class="col-sm-6 form-group">
          <label>CST COFINS</label>
          <ng-select appendTo="#modal-alterar-dados-fiscais" [items]="cstCOFINS" bindLabel="descricao" bindValue="tipo"
            placeholder="Selecione" formControlName="situacaoTributariaCOFINS"></ng-select>
          <show-errors [control]="cadastroForm.controls.situacaoTributariaCOFINS"></show-errors>
        </div>
      </div>
    </div>

    <div class="form-button-row">
      <button class="btn btn-primary custom-form-button" type="submit" [disabled]="loading">
        <app-loading [loading]="loading" [texto]="'Salvar'" [textoLoading]="'Salvando'" [icone]="'icofont icofont-save'"></app-loading>
      </button>
      <button class="btn btn-danger custom-form-button" type="button" (click)="modalCadastro.hide()">
        <i class="ti ti-close"></i> Cancelar
      </button>
    </div>
  </form>
</app-temp-modal-cadastro>

<app-abrir-modal #abrirModal></app-abrir-modal>
