<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader" modalContentId='modal-cadastro-servico'>
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">Nome</label>
        <input [disableControl]="loading['registro']" type="text" class="form-control"
          formControlName="nome" maxlength="120" />
        <show-errors [control]="cadastroForm.controls.nome"></show-errors>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <label>Descrição Complementar</label>
        <input [disableControl]="loading['registro']" type="text" class="form-control"
          formControlName="descricaoComplementar" maxlength="500" />
        <show-errors [control]="cadastroForm.controls.descricaoComplementar"></show-errors>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <label>Valor</label>
        <input [disableControl]="loading['registro']" type="text" class="form-control"
          currencyMask [options]="{ prefix: '', thousands: '.', decimal: ',', precision: 2, align: 'left' }"
          formControlName="precoSaida" />
        <show-errors [control]="cadastroForm.controls.precoSaida"></show-errors>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">Código de Tributação Nacional</label>
        <ng-select appendTo="#modal-cadastro-servico" [loading]="!servicosISSQN" formControlName="codigoIssqn"
          placeholder="Selecione" [items]="servicosISSQN" bindLabel="name" bindValue="id"
          [disableControl]="loading['request'] || loading['disable']">
        </ng-select>
        <show-errors [control]="cadastroForm.controls.codigoIssqn"></show-errors>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar
    </button>
  </form>
</app-temp-modal-cadastro>