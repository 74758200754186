import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ModalCadastroComponent } from '../modal-cadastro/modal-cadastro.component';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dim-exportar-consulta',
  templateUrl: './dim-exportar-consulta.component.html',
  styleUrls: ['./dim-exportar-consulta.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DimExportarConsulta),
      multi: true
    }
  ]
})
export class DimExportarConsulta
  extends ModalCadastroComponent
  implements ControlValueAccessor
{

  constructor(public controlContainer: ControlContainer) { super(); }
  private onChanged = (_: any) => { };
  private onTouched = (_: any) => { };
  writeValue(_: any): void {}
  registerOnChange(_: any): void { this.onChanged = _; }
  registerOnTouched(_: any): void { this.onTouched = _; }
  @ViewChild('modal', { static: false }) private modal: any;
  @ViewChild('title') title: ElementRef<any>;

  @Output() exportar: EventEmitter<any> = new EventEmitter<string>();
  @Input() exportando: boolean = false;

  @Input() validacao: boolean = true;
  @Input() mostrarErro: boolean = true;
  @Input() exportarLabel: string = 'Exportar';
  @Input() exportandoLabel: string = 'Exportando';
  @Input() cancelarLabel: string = 'Cancelar';
  @Input() formLabels: any = {};
  @Input() colSize: string = 'col-6';

  @Input() xlsx: boolean = true;
  @Input() xml: boolean = false;
  @Input() zip: boolean = false;

  exportFileTypeForm = this.getDefaultExportFileTypeForm();
  exportFileTypeLabels = {
    pdf: 'PDF',
    xlsx: 'XLSX',
    xml: 'Arquivo único',
    zip: 'Arquivo zip',
  };

  /**
   * Cria e configura um FormGroup com checkboxes para opções de exportação de arquivos.
   *
   * @returns {FormGroup} Um FormGroup contendo os controles de checkbox para os tipos de exportação disponíveis.
   *
   * @description
   * Este método privado cria um FormGroup com checkboxes que representam as opções de tipo de arquivo disponíveis para exportação.
   * Por padrão, a opção "PDF" está sempre habilitada, e as opções "XLSX", "XML" e "ZIP" são opcionais,
   * dependendo das propriedades `xlsx`, `xml` e `zip` do componente.
   * Se alguma dessas propriedades for falsa, o controle correspondente é removido do FormGroup.
   */
  private getDefaultExportFileTypeForm() {
    const f = new FormGroup({
      pdf: new FormControl(true),
      xlsx: new FormControl(false),
      xml: new FormControl(false),
      zip: new FormControl(false),
    });

    if (!this.xlsx) {
      f.removeControl('xlsx');
    }
    if (!this.xml) {
      f.removeControl('xml');
    }
    if (!this.zip) {
      f.removeControl('zip');
    }

    return f;
  }

  get form() {
    const fg = this.controlContainer.control as FormGroup;
    return fg;
  }

  abrir(): void {
    this.modal.show();
  }

  fechar(): void {
    this.modal.hide();
  }

  handleOnClose(_: PointerEvent): void {
    this.form.setValidators(null);
    this.closed.emit();
  }

  /**
   * Emite um evento de exportação com o tipo de arquivo selecionado, caso o formulário seja válido.
   *
   * @param {PointerEvent} _ - O evento do ponteiro que disparou a ação de exportação (não utilizado na lógica atual).
   */
  onExport(_: PointerEvent): void {
    if (!this.form.valid) {
      return;
    }

    this.form.setValidators(null);
    const selected = Object.keys(this.exportFileTypeForm.value).find(k => this.exportFileTypeForm.value[k]);
    this.exportar.emit(selected);
  }

  onCancel(_: PointerEvent): void {
    this.form.setValidators(null);
    this.fechar();
  }
}

export interface ExportFileTypeOptions {
  pdf: boolean;
  xlsx: boolean;
  xml: boolean;
  zip: boolean;
}

// Utilizar para o @ViewChild
export interface DimExportarConsultaComponentRef {
  abrir: Function;
  fechar: Function;
}
